import React, { Component } from "react";
import MdTemplate from "../components/MdTemplate";
import { graphql } from "gatsby";

const drinkCardStyle = {
  border: "1px yellow solid",
  height: "100px",
  width: "400px",
  marginBottom: "40px"
};

export const DrinkCardTemplate = ({ title, direction, bitter, spirit }) => {
  return (
    <div className="column is-4">
      <div
        className="card"
        style={{
          borderRadius: "5px"
          // boxShadow: "3px 3px 2px 5px #F4F4F8"
        }}
      >
        <div
          className="card-content"
          style={
            {
              // backgroundColor: "#ffffff",
              // // backgroundColor: "#ffffff",
              // backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23eae3d5' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
            }
          }
        >
          <h2 className="subtitle is-2">{title}</h2>
          {/* <p className="subtitle">{name}</p> */}
          {/* <p>{directions}</p> */}
          <div className="content">
            {/* <HTMLContent content={post.html} /> */}
            {/* {post.frontmatter.directions} */}
            <MdTemplate mdString={direction} />
          </div>
        </div>
        <footer className="card-footer">
          <p
            className="card-footer-item is-warning"
            style={{ backgroundColor: "#C0B71A" }}
          // style={{ backgroundColor: "#FFDD23" }}
          >
            <span>{spirit}</span>
          </p>
          <p
            className="card-footer-item"
            style={{ backgroundColor: "#EA8A52" }}
          // style={{ backgroundColor: "#CC6600" }}
          >
            <span>{bitter}</span>
          </p>
        </footer>
      </div>
    </div>
  );
};
const DrinkCard = props => {
  const {
    recipe: { title, direction, bitter, spirit }
  } = props;

  return (
    <DrinkCardTemplate
      title={title}
      direction={direction}
      bitter={bitter}
      spirit={spirit}
    />
  );
};

export default DrinkCard;

// export const drinkCardQuery = graphql`
//   query DrinkCardQuery {
//     allRecipesJson(filter: { title: { eq: "Benediction" } }) {
//       edges {
//         node {
//           name
//           spirit
//           bitter
//           direction
//           title
//         }
//       }
//     }
//   }
// `;

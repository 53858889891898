import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import DrinkCard from "../components/DrinkCard.js";
import { multiFilter } from "../lib/helpers";
import { bitters, spirits } from "../constants/constants";
export default class Recipes extends React.Component {
  constructor(props) {
    super(props);
    this.onChange_handler = this.onChange_handler.bind(this);
    this.reset_handler = this.reset_handler.bind(this);
    this.state = {
      spirit: "",
      bitter: ""
    };
  }
  log;

  onChange_handler(event) {
    console.log(event.target.value);
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState({
      [event.target.id]: value
    });
  }

  reset_handler(event) {
    this.setState({
      spirit: "",
      bitter: ""
    });
  }

  render() {
    const { data } = this.props;
    const recipes = data.allRecipesJson.edges.map(edge => edge.node);
    console.log(data);

    const buildFilter = () => {
      const filter = {};
      Object.keys(this.state).forEach(key => {
        return this.state[key] ? (filter[key] = this.state[key]) : {};
      });
      return filter;
    };
    var filteredRecipes = multiFilter(recipes, buildFilter());

    return (
      <Layout>
        <div className="container">
          <div className="section">
            <h1 className="title is-1">Recipe Vault</h1>
            <h4 className="subtitle is-4">
              Our collection of handcrafted recipes
            </h4>
          </div>
          <div className="box">
            <h1 className="subtitle is-1">Filter by:</h1>
            <div className="field is-horizontal">
              <div className="control">
                <div className="select is-large">
                  <select
                    className=""
                    id="spirit"
                    style={{ marginRight: 20 }}
                    value={this.state.spirit}
                    onChange={this.onChange_handler}
                  >
                    <option value="">All Spirits</option>
                    {Object.keys(spirits).map(spirit => (
                      <option value={spirit}>{spirit}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="control">
                <div className="select is-large">
                  <select
                    name=""
                    id="bitter"
                    style={{ marginRight: 20 }}
                    value={this.state.bitter}
                    onChange={this.onChange_handler}
                  >
                    <option value="">All Bitters</option>
                    {Object.keys(bitters).map(bitter => (
                      <option value={bitter}>{bitter}</option>
                    ))}
                  </select>
                </div>
              </div>
              <p className="control">
                <a
                  className="button is-large is-warning"
                  onClick={this.reset_handler}
                >
                  Show all
                </a>
              </p>
            </div>

            {/* <p>{JSON.stringify(this.state)}</p> */}
            <div className="section">
              <div
                className="columns is-multiline"
                style={
                  {
                    // backgroundColor: "#ffffff",
                    // // backgroundColor: "#ffffff",
                    // backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23eae3d5' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
                  }
                }
              >
                <div className="column is-10 is-offset-1" />

                {filteredRecipes.length ? (
                  filteredRecipes.map((recipe, index) => (
                    <DrinkCard key={index} recipe={recipe} />
                  ))
                ) : (
                    <p>
                      No recipes with that spirit & bitter.
                      <p className="control">
                        <a
                          className="button is-large is-warning"
                          onClick={this.reset_handler}
                        >
                          Show all
                      </a>
                      </p>
                    </p>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

// Recipes.propTypes = {
//   data: PropTypes.shape({
//     allMarkdownRemark: PropTypes.shape({
//       edges: PropTypes.array,
//     }),
//   }),
// }

export const recipeQuery = graphql`
  query RecipesQuery {
    allRecipesJson {
      edges {
        node {
          spirit
          bitter
          direction
          title
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

// export const drinkCardQuery = graphql`
//   fragment on recipes {
//     allRecipesJson(filter: { title: { eq: "Benediction" } }) {
//       edges {
//         node {
//           name
//           spirit
//           bitter
//           direction
//           title
//         }
//       }
//     }
//   }
// `;

export const query = graphql`
  fragment SiteInformation on Site {
    siteMetadata {
      title
    }
  }
`;
